import React, { useEffect, useState } from "react";
import Button from '../../../../components/Buttons/Button'
import Box from '../../../../components/Box/Box'
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import {useParams, useNavigate} from "react-router-dom";
import { fetchSingleBlog, updateBlog } from "../../mosqueAction";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import _ from 'lodash';
import DeleteComponent from "../../components/DeleteComponent";
import DefaultModal from "../../../../components/DefaultModal/DefaultModal";
import { useTranslation } from "react-i18next";

function BlogDetailsPage() {
    const {id, url} = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState("");

    const edit_blog = useSelector(state => state.mosqueReducer.edit_blog);
    const activeEntity = useSelector(state => state.mosqueReducer.activeEntity);
    console.log(edit_blog);
    useEffect(() => {
        dispatch(fetchSingleBlog(id));
    }, [id])

    let dataList = [
        {label: t(`Gepubliceerde nieuwsberichten`), link: `/mosque/${activeEntity._id}/blogs`, isBold: true, color: "#222"},
        {label: t(`Gedetailleerde nieuwsbericht`), link: "", isBold: true, color: "#7E7E7E"},
    ];

    const handleEditBlog = () => {
        navigate(`/mosque/${activeEntity._id}/blogs/${edit_blog._id}/edit`);
    };

    const handleRemoveBlog = (blog_id) => {
        const blog = {};
        blog.is_deleted = true;
        dispatch(updateBlog(blog_id, blog, true))
        navigate(`/mosque/${activeEntity?._id}/blogs`);
    }

    const onClickRemove = (entityId) => {
        setShowDeleteModal(true);
        setSelectedId(entityId);
    }

    return (
        <div className='blog-details '>
            <div className='d-flex justify-content-between align-items-center'>
                <Box borderRadius="4px" color="#FFFFFF" px="30px" py="10px" style={{fontWeight:"500"}}>
                    <Breadcrumbs
                        dataList={dataList}
                        marginLeft = "5px"
                    />
                </Box>
                <div className="d-flex">
                    <Button
                        onClick={() => handleEditBlog()}
                        bg="#D0004B"
                        text={t("Bewerk nieuwsbericht")}
                        mt="18px"
                        mb="18px"
                        mr="15px"
                        textColor="#fff"
                        borderRadius="66px"
                    />
                    <Button
                        onClick={() => onClickRemove(edit_blog?._id)}
                        type="primary"
                        text={t("Verwijder nieuwsbericht")}
                        mt="18px"
                        mb="18px"
                    />
                </div>
            </div>
            <div className='details-card-blog'>
                <>
                    <Box display="flex" gridGap="8px">
                        {_.isArray(edit_blog.tags) && edit_blog?.tags?.map(tag => (
                           <Button
                           key={tag}
                           mt="18px"
                           mb="18px"
                           mr="4px"
                           bg="rgba(208, 0, 75, 0.08)"
                           textColor="#D0004B"
                           borderRadius="6px"
                       >
                         <span style={{ color: "#D0004B" }}>{tag}</span>
                       </Button>
                        ))}
                    </Box>
                    <h2>{edit_blog?.heading}</h2>

                    <div className="blog-profile mb-4 mt-4">
                        {edit_blog?.author_image ?
                            <img src={edit_blog?.author_image} style={{objectFit:'cover'}} alt={`author image`} />
                            :
                            null
                        }<span>{edit_blog?.author_name}</span>
                        <div className="border-right"></div>
                        <span>{moment(new Date(edit_blog?.published_date)).format('MMM DD, YYYY')}</span>
                    </div>
                    {edit_blog?.preview_image ?
                        <img
                            src={edit_blog?.preview_image}
                            alt={'blog img'}
                            style={{width: "100%"}}
                            className="blog-detail-img-height"
                        /> :
                        null
                    }
                    <br/>
                    <br/>
                    <br/>
                    <div style={{ 
                        wordWrap: 'break-word', 
                        overflowWrap: 'break-word', 
                        overflowY: 'auto', 
                        maxHeight: '400px',
                        padding: '10px'
                        }} >{edit_blog?.text}</div>
                </>
            </div>

            <DefaultModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                className="congrats-modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <DeleteComponent
                    text={t("U verliest permanent de details van dit nieuwsbericht en andere informatie.")}
                    handleDelete={() => handleRemoveBlog(selectedId)}
                    show={showDeleteModal} setshow={setShowDeleteModal} />
            </DefaultModal>
        </div>
    );
}

export default BlogDetailsPage;

import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import moment from "moment";
import "moment/locale/nl";
import truncate from "lodash/truncate";
import Button from "../../../../../components/Buttons/Button";
import PaginationV2 from "../../../../../components/Pagination/PaginationV2";
import {fetchAgentBlogList} from "../../../mosqueAction";
import Box from "../../../../../components/Box";
import useActiveData from "../../../../../customHooks/useActiveData";
import { useTranslation } from "react-i18next";

moment.updateLocale('nl', {
    months : [
        "Jan", "Feb", "Mrt", "Apr", "Mei", "Jun",
        "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"
    ]
});

const BlogCard = () => {
    const websiteData = useActiveData();
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
    const blogList = useSelector((state) => state.mosqueReducer.blog_list);
    const blogCount = useSelector(
        (state) => state.mosqueReducer.total_blog_count
    );
    const currentPage = useSelector((state) => state.mosqueReducer.blog_page_num);
    const pageSize = useSelector((state) => state.mosqueReducer.blog_page_size);
    const onPageChange = (page) => {
        if (activeEntity?._id) {
            dispatch(
                fetchAgentBlogList({
                    entity_id: activeEntity._id,
                    pageNum: page,
                    pageSize: pageSize,
                })
            );
        }
    };

    return (
        <div className="container">
            <div className="row">
                {blogList?.length === 0 ?
                    <div className={"col-md-12"}>
                        <br/><br/><br/>
                        <div className="text-center">
                            {t(websiteData?.BLOG_LIST_PAGE_BLANK_TEXT)}
                        </div>
                    </div>
                    :
                    null
                }
                {blogList &&
                    blogList.map((item) => (
                        <div className="col-lg-4 mb-4">
                            <Link
                                to={`/mosque/${item?._id}/blogs/${item?.url}/info`}
                                style={{textDecoration: "none"}}
                            >
                                <div className="blog-card" >
                                    {/* <img src={item?.preview_image} height="185px" /> */}
                                    {!item?.preview_image ? (
                                        <img
                                            src="https://ik.imagekit.io/rzlzf40okya/elixir/defaultimg.svg"
                                            className="mosque_blog_img"
                                            alt=""
                                        />
                                    ) : (
                                        <div className="mosque_blog_img">
                                            <img
                                            src={item?.preview_image}
                                            alt=""
                                            height={'100%'}
                                            width={'100%'}
                                            
                                        />
                                    </div>        
                                    )}
                                    <Box display="flex" flexWrap="wrap">
                                        {item?.tags.map((tag) => (
                                            <Button
                                                key={tag}
                                                mt="18px"
                                                mb="18px"
                                                mr="4px"
                                                bg="rgba(208, 0, 75, 0.08)"
                                                textColor="#D0004B"
                                                borderRadius="6px"
                                            >
                                                <span style={{color: "#D0004B"}}>{tag}</span>
                                            </Button>
                                        ))}
                                    </Box>
                                    <h4
                                        style={{
                                            color: "#1a1a1a",
                                            wordBreak: "break-all",
                                            height: "84px",
                                        }}
                                    >
                                        {truncate(item?.heading, {length: 75})}
                                    </h4>
                                    {/* <h6 style={{ color: "#1a1a1a", wordBreak: "break-all", height: "40px" }}>
                      <div dangerouslySetInnerHTML={{ __html : truncate(item?.text, { length: 70 })}}></div>
                  </h6> */}
                                    <div className="blog-profile">
                                        {/* <img src={item?.author_image} /> */}
                                        {!item?.author_image ? (
                                            <img
                                                src="https://ik.imagekit.io/rzlzf40okya/elixir/auth_im.svg"
                                                alt=""
                                                className="auth_img"
                                            />
                                        ) : (
                                            <img
                                                src={item?.author_image}
                                                alt=""
                                                className="auth_img"
                                            />
                                        )}
                                        <span style={{wordBreak: "break-all"}}>
                      {item?.author_name}
                    </span>
                                        <div className="border-right"></div>
                                        <span>
                      {/*{moment(new Date(item?.published_date)).format(
                          "MMM DD, YYYY"
                      )}*/}{moment(new Date(item?.published_date)).format("MMM DD, YYYY")}
                    </span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
            </div>

            <PaginationV2
                currentPage={currentPage}
                pageSize={pageSize}
                totalCount={blogCount}
                onPageChange={onPageChange}
            />
        </div>
    );
};

export default BlogCard;

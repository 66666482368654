import React, { useState, useEffect } from "react";
import Box from "../../../../../components/Box";
import Icon from "../../../../../components/Icon";
import styled from "styled-components";
import useOutsideClick from "../../../../../customHooks/useOutsideClick";
import Label from "../../../../../components/Label";
import { useTranslation } from "react-i18next";

const Container = styled(Box)`
  min-height: 115px;
  max-height: 115px;
  overflow: auto;

  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 3px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    // background: #9197b3;
    // border-radius: 5px;
    background-image: url("https://ik.imagekit.io/rzlzf40okya/elixir/Line.svg?updatedAt=1689592501360");
    background-repeat: no-repeat;
  }
`;

const IconContainer = styled(Icon)`
  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "unset")};
`;

const DropdownItemBox = styled(Box)`
  width: 160px;
  position: absolute;
  background: rgb(245, 245, 245);
  z-index: 99;
  margin-left: -10px;
  border-radius: ${(props) => (props.isOpen ? "0 0 8px 8px" : "8px")};
`;

const TextStyle = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 3px 5px;
  font-size: 15px;
  font-weight: 500;
  
  &:hover {
    background: #cf004b;
    color: #fff;
    border-radius: 4px;
  }
`;

const SortBy = [
  {
    label: "Laatste 30 dagen",
    value: "30",
  },
  {
    label: "Laatste 15 dagen",
    value: "15",
  },
  {
    label: "Laatste 7 dagen",
    value: "7",
  },
  {
    label: "Alles",
    value: "",
  }
];

const CheckBoxDateRange = ({ value, handleSortBy }) => {
  const [open, setOpen] = useState(false);
  const {t} = useTranslation()
  const [iconTheme, setIconTheme] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const SortBy = [
    {
      label: t("Laatste 30 dagen"),
      value: "30",
    },
    {
      label: t("Laatste 15 dagen"),
      value: "15",
    },
    {
      label: t("Laatste 7 dagen"),
      value: "7",
    },
    {
      label: t("Alles"),
      value: "",
    }
  ];

  const setInitialValue = () => {
    setOpen(false);
  };

  const ref = useOutsideClick(() => setInitialValue());

  useEffect(() => {
    if (!!value.length) {
      setSelectedItems([...value]);
    }
  }, [value]);

  useEffect(() => {
    checkIconFilled();
  }, [selectedItems]);

  const onChangeSelection = (keyValue) => {
    setOpen(false);
    handleSortBy(keyValue);
  };

  const checkIconFilled = () => {
    if (selectedItems.length) {
      setIconTheme({ theme: "filled" });
    } else {
      setIconTheme({});
    }
  };

  const checkboxRender = () => {
    return (
      <DropdownItemBox value={selectedItems} isOpen={open}>
        <Container>
          {SortBy.map((group, i) => (
              <TextStyle onClick={() => onChangeSelection(group?.value)}>
                  {t(group?.label)}
              </TextStyle>
          ))}
        </Container>
      </DropdownItemBox>
    );
  };

  const renderData = (val) => {
    const filteredData = SortBy.filter(item => item.value === val)?.[0];
    return filteredData?.label;
  }

  return (
    <Box position="relative" ref={ref}>
      <Box
        onClick={() => setOpen(!open)}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Label variant="text8" color="#7E7E7E">
          {value !== "" ? renderData(value) : "All"}
        </Label>{" "}
        <IconContainer iconName="arrowBottom" p="10px" isOpen={open} />
      </Box>
      {open && checkboxRender()}
    </Box>
  );
};

export default CheckBoxDateRange;

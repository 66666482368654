import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";
import Input from "../../../../../components/Input/Input";
import Button from "../../../../../components/Buttons";
import { useDispatch } from "react-redux";
import { ResetPassword } from "../../../adminAction";
import { useTranslation } from "react-i18next";
import { showNotification } from "../../../../../util/Utility";
import axios from "axios";
import { useSearchParams } from 'react-router-dom';

const LoginTabContainer = styled.div`
  position: relative;
  top: -100px;
`;
const LoginModal = styled.div`
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 452px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 20px;
  padding: 34px;
`;

const DetailsBody = styled.div`
  margin-top: 30px;
`;

const BodyContainer = ({ resetToken, resetEmail ,setIsPasswordChange}) => {
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({});
  const [isNext, setIsNext] = useState(false);
  const {t} = useTranslation()
  const [searchParams] = useSearchParams();
  const [agentEmail, setAgentEmail] = useState(null)
  const [isError, setIsError] = useState(false)

  const stepValidation = () => {
    return !!userDetails?.password && !!userDetails?.confirmPassword;
  };

  useEffect(() => {
    if (userDetails) {
      setIsNext(stepValidation());
    }
    setAgentEmail(searchParams.get('email'))
  }, [userDetails]);

  const handleChange = async () => {
    if(userDetails?.confirmPassword !== userDetails?.password)
    {
      setIsError(true)
      return;
    }
    try {
       const payload= {
            agent: {
              password: userDetails?.password,
          }
        }
        const baseUrl =
        process.env.REACT_APP_STAGE === 'development'
          ? 'apidev.mosqapp.com'
          : 'api.mosqapp.com';
        const res = await axios.post(`https://${baseUrl}/agent/auth/reset-password/${resetToken}`, payload);
        console.log(res.data);
        if(res.data.status === 'Success'){
            setIsPasswordChange(true);
            showNotification("success", "Wachtwoord succesvol gewijzigd.");
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            localStorage.removeItem("user_type");
            localStorage.removeItem("_expiredTime");
        }
        
    } catch (error) {
        showNotification(
            "error",
            error.message || "Er is iets misgegaan.."
          );
    }
  }

  return (
    <LoginTabContainer>
      <LoginModal>
        <Label variant="text2" fontWeight={700} justifyContent="center">
          {t('Reset Password')}
        </Label>
        <DetailsBody>
        <Label variant="text5" fontWeight={500} mb="10px">
          {t('Email Address')}
          </Label>
          <Input
            value={agentEmail}
            border="1px solid #EFF0F7"
            boxShadow="0px 2px 6px #13124212"
            borderRadius="8px"
            placeholder="Email Address"
            className="input-box"
            isDisabled={true}
            mb="20px"
          />
          <Label variant="text5" fontWeight={500} mb="10px">
          {t('Enter New Password')}
          </Label>
          <Input
            value={userDetails?.password}
            onChange={(e) =>
              setUserDetails({ ...userDetails, password: e.target.value })
            }
            border="1px solid #EFF0F7"
            boxShadow="0px 2px 6px #13124212"
            borderRadius="8px"
            placeholder={t("Enter New Password")}
            className="input-box"
            onClick={()=>setIsError(false)}
            isEyeButton
          />
          <Label variant="text5" fontWeight={500} mt="24px" mb="12px">
          {t('Re-Enter New Password')}
          </Label>
          <Input
            value={userDetails?.confirmPassword}
            onChange={(e) =>
              setUserDetails({
                ...userDetails,
                confirmPassword: e.target.value,
              })
            }
            border="1px solid #EFF0F7"
            boxShadow="0px 2px 6px #13124212"
            borderRadius="8px"
            placeholder={t("Re-Enter New Password")}
            className="input-box"
            onClick={()=>setIsError(false)}
            isEyeButton
          />
        </DetailsBody>

        <Box display="flex" justifyContent="center" mt="30px">
          <Button
            onClick={() => handleChange()}
            width="40px" type={isNext ? "primary" : "disabled"}>
            {t('Next')}
          </Button>
        </Box>
        { isError && <div className="error-message" style={{justifyContent:'center', marginTop:'20px'}}>{t('Password & Confirm Password should match')} !!</div>}
      </LoginModal>
     
    </LoginTabContainer>
  );
};

export default BodyContainer;

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "../../../../../css/addmember.css";
import Button from "../../../../../components/Buttons/Button";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import Icon from "../../../../../components/Icon/Icon";
import Input from "../../../../../components/Input";
import {
    addNewMosque,
    entityApprove,
    entityDecline,
    setMosqueDetails,
    updateAddedNewFlag,
    updateMosque
} from "../../../adminAction";
import {DatePicker} from "antd";
import moment from "moment";
import {getPaymentMethodOption} from "../../../../../util/Utility";
import Dropdown from "../../../../../components/Dropdown";
import styled from "styled-components";
import dayjs from "dayjs";
import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";
import {UPDATE_APPROVAL_STATUS, UPDATE_DECLINE_STATUS} from "../../../adminActionType";
import {useNavigate} from "react-router-dom";
import ErrorMessage from "../../../../../components/ErrorMessage";
import { fetchCities, fetchCountries } from "../../../../Mosque/mosqueAction";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const DatePickerBox = styled.div`
  .ant-picker {
    width: 100%;
    height: 54px;
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }

  .ant-picker-focused {
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }

  .ant-picker .ant-picker-input > input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #1a1a1a;
  }
`;

const paymentMethodOption = getPaymentMethodOption();



const MosqueForm = ({detailsMosque = null, entityId = null, approvalId = null}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const countries = useSelector((state) => state.mosqueReducer.countries);
    const cities = useSelector((state) => state.mosqueReducer.cities);
    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false);
    const [isNext, setIsNext] = useState(false);
    const [details, setDetails] = useState({});
    const [isDecline, setIsDecline] = useState(false);
    const {t} = useTranslation()
    const isFetchingCountries = useSelector(
      (state) => state.mosqueReducer.isFetchingCountries
    );
    const isFetchingCities = useSelector(
      (state) => state.mosqueReducer.isFetchingCities
    );
    const isApprovedApproval = useSelector((state) => state.adminReducer.isApprovedApproval);
    const isApprovalDecline = useSelector((state) => state.adminReducer.isApprovalDecline);
    const mosqueDetails = useSelector((state) => state.adminReducer.mosqueDetails);
    const plan_list = useSelector((state) => state.adminReducer.plans?.plan_list);
    const isAddedNew = useSelector((state) => state.adminReducer.isAddedNew);

    useEffect(() => {
        if (detailsMosque) {
            const data = {};
            data.entity_name = detailsMosque?.name;
            data.entity_address = detailsMosque?.address;
            data.entity_pincode = detailsMosque?.pincode;
            data.entity_country = detailsMosque?.country;
            data.entity_city = detailsMosque?.city;
            data.entity_merchant_key = detailsMosque?.entity_merchant_key;
            data.entity_merchant_id = detailsMosque?.entity_merchant_id;
            data.contact_name = detailsMosque?.primary_user_id?.contact_name;
            data.contact_email = detailsMosque?.primary_user_id?.contact_email;
            data.contact_phone = detailsMosque?.primary_user_id?.contact_phone;
            data.country_code = detailsMosque?.primary_user_id?.country_code;
            data.contact_address = detailsMosque?.primary_user_id?.address;
            data.payment_method = detailsMosque?.organisation_id?.payment_method;
            data.saas_subscription_plan_id =
                detailsMosque?.organisation_id?.saas_subscription_plan_id;
            data.membership_date = detailsMosque?.organisation_id?.membership_date ?? detailsMosque?.organisation_id?.created_at;
            setDetails(data);
        } else {
            setDetails(mosqueDetails);
        }
    }, [detailsMosque, mosqueDetails]);

  const formValidation = () => {
    return !!details?.entity_name &&
      !!details?.entity_address &&
      !!details?.entity_pincode &&
      !!details?.entity_country &&
      !!details?.entity_city &&
      !!details?.contact_name &&
      !!details?.contact_email &&
      !!details?.contact_phone &&
      !!details?.country_code &&
      !!details?.membership_date &&
      !!details?.entity_merchant_id &&
      !!details?.entity_merchant_key 
  }
    useEffect(() => {
        if (details) {
            setIsNext(formValidation());
        }
    }, [details]);

    const handleChangeUser = (keyName, keyValue) => {
        if(keyName === "contact_email"){
            keyValue = keyValue.toLowerCase();
        }
      dispatch(setMosqueDetails(keyName, keyValue));
      
      
      if(entityId && approvalId){
        if(keyName === "entity_merchant_id" && detailsMosque){
          detailsMosque.entity_merchant_id = keyValue;
        }
         if(keyName === "entity_merchant_key" && detailsMosque){
          detailsMosque.entity_merchant_key = keyValue
         }
      }
    };

    const optionsPlan = () => {
        let listValues = [];
        if (plan_list) {
            const list = plan_list?.forEach((reply, index) => {
                listValues.push({
                    ...reply,
                    label: reply?.plan_name,
                    value: reply?._id,
                });
            });
        }
        return listValues;
    };

  const handleAddMosque = () => {
    dispatch(addNewMosque(true, 'SIGN_UP'));
  };

  const handleAddNew = () => {
    dispatch(updateAddedNewFlag(false));
    window.location.href = "/admin/mosques"
  };

  useEffect(() => {
    if (isApprovalDecline) {
      dispatch({
        type: UPDATE_DECLINE_STATUS,
        isApprovalDecline: false
      });
      setDetails(mosqueDetails);
      setIsDecline(false);
      navigate("/admin/mosques");
    }
  }, [isApprovalDecline]);
useEffect(()=>{
  dispatch(fetchCountries());

},[])
  const handleApproval = () => {
    dispatch({
      type: UPDATE_APPROVAL_STATUS,
      isApprovedApproval: false
    });

    window.location.href = `/admin/mosques`;
  }

  const handleClickDecline = () => {
    if (approvalId) {
      dispatch(entityDecline(approvalId));
    }
  }

  const handleClickApproval = () => {
    console.log(details);
    if (entityId && approvalId) {
      dispatch(entityApprove(entityId, approvalId, details));
      setIsNext(formValidation());
    }
  }

  console.log(' mosque detail -------------------> ',details);
  

  const getRenderButton = () => {
    if (detailsMosque) {
      return (
          <Box display="flex" justifyContent="center">
            <Box>
              <Button
                  type="outlineColor"
                  text={t("Moskee afwijzen")}
                  borderRadius="66px"
                  minWidth="200px"
                  mt="4"
                  mx="auto"
                  height="50px"
                  onClick={() => setIsDecline(true)}
              />
            </Box>
            <Box ml="15px">
              <Button
                  type={(!details.entity_merchant_id || !details.entity_merchant_key)?"disabled":"primary"}                  
                  text={t("Moskee goedkeuren")}
                  borderRadius="66px"
                  minWidth="200px"
                  mt="4"
                  mx="auto"
                  height="50px"
                  onClick={() => handleClickApproval()}
              />
            </Box>
          </Box>
      );
    } else {
      return (
          <Button
              type={!isNext ? "disabled" : "primary"}
              text={t("Gegevens opslaan")}
              borderRadius="66px"
              width="15%"
              mt="4"
              mx="auto"
              height="60px"
              onClick={() => handleAddMosque()}
          />
      );
    }
  };

  return (
    <div className="add_form form_width">
      <div className="mosque-form p-2" style={{paddingRight:"80px"}}>
        <h2>{t('Moskee toevoegen')}</h2>
        <div className="row p-2">
          <div className="col-lg-6" style={{paddingRight:"40px"}}>
            <label>
            {t('Naam Moskee')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
              value={details?.entity_name}
              onChange={(e) => handleChangeUser("entity_name", e.target.value)}
              type="text"
              placeholder={t("Voer Naam Moskee In")}
              className="input-box"
            />
          </div>
          <div className="col-lg-6" style={{paddingRight:"40px"}}>
            <label>
            {t('Adres Moskee')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={details?.entity_address}
              onChange={(e) =>
                handleChangeUser("entity_address", e.target.value)
              }
              type="text"
              placeholder={t("Voer Adres Moskee In")}
              className="input-box"
            />
          </div>

          <div className="col-lg-6" style={{paddingRight:"40px", marginTop:"20px"}}>
            <label>
              {t('Postcode')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={details?.entity_pincode}
              onChange={(e) =>
                handleChangeUser("entity_pincode", e.target.value)
              }
              type="text"
              placeholder={t("Voer postcode in")}
              className="input-box"
            />
          </div>
          <div className="col-lg-6" style={{paddingRight:"40px", marginTop:"20px"}}>
          {!countries?.length ? (
              <div className="d-flex justify-content-center align-items-center" style={{height:"100%"}}>

              <Spinner />
              </div>
            ) : (
              <>
            <label>
            {t('Land Moskee')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Dropdown
                allowSearch
                variant="outlinedGreen"
                defaultText={t("Selecteer Land")}
                color="#1a1a1aad"
                fontWeight="500"
                width="100%"
                height="54px"
                Icon="none"
                selectedValue={details?.entity_country}
                onOptionClick={(item) => {
                  handleChangeUser("entity_country", item?.value)
                  if(details?.entity_country !== item?.value){
                    dispatch(fetchCities(item?.value));
                    handleChangeUser("entity_city", "")
                  }
                }}
                options={countries}
            />
          </>)}
          </div>
          <div className="col-lg-6 customDatePicker" style={{paddingRight:"40px", marginTop:"20px"}}>
            <label>
            {t('Lidmaatschap sinds')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <br></br>
            <DatePickerBox>
              <DatePicker
                  defaultValue={dayjs()}
                  value={dayjs(details?.membership_date, "YYYY-MM-DD")}
                  onChange={(value) => handleChangeUser("membership_date", value)}
              />
            </DatePickerBox>
          </div>
            <div className="col-lg-6" style={{paddingRight:"40px", marginTop:"20px"}}>
            {isFetchingCities ? (
              <div className="d-flex justify-content-center align-items-center" style={{height:"100%"}}>

                <Spinner />
              </div>
            ) : (
              <>
            <label>
            {t('Stad Moskee')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Dropdown
                allowSearch
                variant="outlinedGreen"
                defaultText={t("Selecteer stad")}
                color="#1a1a1aad"
                fontWeight="500"
                width="100%"
                height="54px"
                Icon="none"
                selectedValue={details?.entity_city}                
                onOptionClick={(item) => handleChangeUser("entity_city", item?.value)}
                options={cities}
             />
             </>)}
          </div>
          <div className="col-lg-6" style={{paddingRight:"40px", marginTop:"20px"}}>
            <label>
             {t('beheerder Naam')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
              value={details.contact_name}
              onChange={(e) => handleChangeUser("contact_name", e.target.value)}
              type="text"
              placeholder={t("Voer beheerdersnaam in")}
              className="input-box"
            />
          </div>
          <div className="col-lg-6" style={{paddingRight:"40px", marginTop:"20px"}}>
            <label>
            {t('E-mailadres')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
              value={details.contact_email}
              onChange={(e) =>
                handleChangeUser("contact_email", e.target.value)
              }
              type="text"
              placeholder={t("Voer uw E-mailadres In")}
              className="input-box"
            />
          </div>
          <div className="col-lg-6" style={{paddingRight:"40px", marginTop:"20px"}}>
            <label>
            {t('Contactnummer')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
              value={details.contact_phone}
              countryCodes={true}
              setCountryCode={(e) => handleChangeUser("country_code", e)}
              onChange={(e) =>
                handleChangeUser("contact_phone", e.target.value)
              }
              // border={user.phone_number.length ? "1px solid #606734" : null}
              placeholder="785-785-4644"
              className="input-box"
              type="number"
              pattern="[0-9]"
              height="52px"
              overflow="unset"
            />
          </div>
            <div className="col-lg-6" style={{paddingRight:"40px",marginTop:"20px"}}>
            <label>
            {t('Adres van beheerder')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={details.contact_address}
              onChange={(e) =>
                handleChangeUser("contact_address", e.target.value)
              }
              type="text"
              placeholder={t("Voer adres in")}
              className="input-box"
            />
          </div>
           <div className="col-lg-6" style={{paddingRight:"40px", marginTop:"20px", marginBottom:"20px"}}>
            <label>
            {t('Merchant-ID')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={details.entity_merchant_id}
              onChange={(e) =>
                handleChangeUser("entity_merchant_id", e.target.value)
              }
              type="text"
              placeholder={t("Voer Merchant-ID in")}
              className="input-box"
            />
          </div>
          <div className="col-lg-6" style={{paddingRight:"40px", marginTop:"20px", marginBottom:"20px"}}>
            <label>
           {t('Merchant Key')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={details.entity_merchant_key}
              onChange={(e) =>
                handleChangeUser("entity_merchant_key", e.target.value)
              }
              type="text"
              placeholder={t("Voer Merchant Key in")}
              className="input-box"
            />
          </div>
          </div>
                </div>
                {getRenderButton()
                }
                <br/>
                <DefaultModal
                    show={isAddedNew}
                    onHide={() => dispatch(updateAddedNewFlag(false))}
                    className="add-mosque-modal"
                    centered={true}
                    showHeader={false}
                    size=""
                    showFooter={false}
                >
                    <div>
                        <Icon
                            iconName="modalClose"
                            iconSize="xs"
                            style={{marginLeft: "auto"}}
                            onClick={() => handleAddNew()}
                        />
                        <div className="text-center add-mosque-modal-content">
                            <Icon
                                iconName="congratulations"
                                style={{justifyContent: "center"}}
                            />
                            <div>{t('Gefeliciteerd')}</div>
                            <div>
                            {t('We hebben de nieuwe moskee toegevoegd. U kunt op elk moment wijzigingen aanbrengen in de bestaande moskee.')}
                            </div>
                            <button onClick={() => handleAddNew()}>{t('Add New Mosque')}</button>
                        </div>
                    </div>
                </DefaultModal>

                <DefaultModal
                    show={isApprovedApproval}
                    onHide={() => handleApproval()}
                    className="add-mosque-modal"
                    centered={true}
                    showHeader={false}
                    size=""
                    showFooter={false}
                >
                    <div>
                        <Icon
                            iconName="modalClose"
                            iconSize="xs"
                            style={{marginLeft: "auto"}}
                            onClick={() => handleApproval()}
                        />
                        <div className="text-center">
                            <img src="https://ik.imagekit.io/rzlzf40okya/elixir/congrats.svg"/>
                            <Label
                                justifyContent="center"
                                fontSize="20px"
                                fontWeight={700}
                                my="20px"
                            >
                                {t('Gefeliciteerd')}
                            </Label>
                            <Label variant="text3" my="20px" color="#7E7E7E">
                            {t('We hebben de nieuwe moskee toegevoegd. U kunt op elk moment wijzigingen aanbrengen in de bestaande moskee.')}
                            </Label>
                        </div>
                    </div>
                </DefaultModal>

                <DefaultModal
                    show={isDecline}
                    onHide={() => setIsDecline(false)}
                    className="add-mosque-modal"
                    centered={true}
                    showHeader={false}
                    size=""
                    showFooter={false}
                >
                    <div>
                        <Icon
                            iconName="modalClose"
                            iconSize="xs"
                            style={{marginLeft: "auto"}}
                            onClick={() => setIsDecline(false)}
                        />
                        <div className="text-center">
                            <img src="https://ik.imagekit.io/rzlzf40okya/elixir/diseble.svg"/>
                            <Label
                                justifyContent="center"
                                fontSize="20px"
                                fontWeight={700}
                                my="20px"
                            >
                                {t('Moskee afwijzen')}
                            </Label>
                            <Label variant="text3" my="20px" color="#7E7E7E">
                            {t('U verliest permanent de details en andere informatie van deze moskee.')}
                            </Label>
                            <Box className="test" display="flex" justifyContent="center">
                                <Button
                                    className="test"
                                    type="outlineColor"
                                    minWidth="200px !important"
                                    width="200px !important"
                                    height="45px !important"
                                    mr="20px !important"
                                    onClick={() => setIsDecline(false)}
                                >
                                    {t('Annuleren')}
                                </Button>
                                <Button
                                    minWidth="200px !important"
                                    width="200px !important"
                                    height="45px !important"
                                    mr="20px !important"
                                    onClick={() => handleClickDecline()}
                                >
                                    {t('Moskee afwijzen')}
                                </Button>
                            </Box>
                        </div>
                    </div>
                </DefaultModal>
            </div>
    );
 };
export default MosqueForm;

import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {setQueryDetails, submitQuery} from "../../../customerAction";
import isEmail from "validator/lib/isEmail";
import _ from "lodash";
import {showNotification} from "../../../../../util/Utility";
import { useTranslation } from "react-i18next";

const ContactDetailSection2 = styled.div`
  display: block;
  @media (max-width: 576px) {
    display: none;
  }
`;
const ContactDetailSectionMobile = styled.div`
  display: none;
  @media (max-width: 576px) {
    display: block;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
    border-radius: 20px;
    margin: 20px;
    padding: 20px;
  }
`;
const CustomButton = styled.button`
  @media (max-width: 576px) {
    max-width: 150px;
  }
`;

const ContactForm = () => {
    const dispatch = useDispatch();
    const [isNext, setIsNext] = useState(false);
    const {t} = useTranslation()

    const contact_us = useSelector((state) => state.customerReducer.contact_us);
    const isFetching = useSelector((state) => state.customerReducer.isFetching);

    const dataValidation = () => {
        let isValid = true;
        let message = "";
        if (!contact_us.last_name) {
            isValid = false;
            message = "Please enter last name";
        }
        if (!contact_us.first_name) {
            isValid = false;
            message = "Please enter first name";
        }
        if (!contact_us.contact_email || !isEmail(contact_us.contact_email)) {
            isValid = false;
            message = "Please enter valid email";
        }
        if (!contact_us.message_query) {
            isValid = false;
            message = "Please enter valid message query";
        }
        return {
            isValid,
            message
        };
    }

    // useEffect(() => {
    //     if (contact_us) {
    //         setIsNext(dataValidation())
    //     }
    // }, [contact_us])

    const handleChangeUser = (keyName, keyValue) => {
        if( keyName === "contact_email"){
            keyValue = keyValue.toLowerCase();
        }
        dispatch(setQueryDetails(keyName, keyValue))
    };

    const handleSubmit = () => {
        let check = dataValidation();
        if (check.isValid) {
            dispatch(submitQuery())
        } else {
            showNotification("error", check.message)
        }
    }

    console.log("contact", contact_us);

    return (
        <div className="new-contact" id="contact-state">

            <div className="contact-box" id="contact-state">
                <h2>{t('Neem contact met ons op')}</h2>
                <div className="container">
                    <div className="contact-form">
                        <div>
                            <h5 className="contact-heading-five">{t('Laat een bericht achter')}</h5>
                            <input
                                value={contact_us?.first_name}
                                onChange={(e) => handleChangeUser("first_name", e.target.value)}
                                type="text"
                                placeholder={t("Voornaam")}
                                className="input-box"
                            />

                            <br/>
                            <br/>
                            <input
                                value={contact_us?.last_name}
                                onChange={(e) => handleChangeUser("last_name", e.target.value)}
                                type="text"
                                placeholder={t("Achternaam")}
                                className="input-box"
                            />
                            <br/>
                            <br/>
                            <input
                                value={contact_us?.contact_email}
                                onChange={(e) => handleChangeUser("contact_email", e.target.value)}
                                type="text"
                                placeholder={t("E-mailadres")}
                                className="input-box"
                            />
                            <br/>
                            <br/>
                            {/* <input
                  value={contact_us?.message_query}
                  onChange={(e) => handleChangeUser("message_query", e.target.value)}
                  type="text"
                  placeholder="Your Message"
                  className="input-box"
                  style={{ height: "97px" }}
                /> */}
                            <textarea
                                value={contact_us?.message_query}
                                onChange={(e) => handleChangeUser("message_query", e.target.value)}
                                placeholder={t("Uw Bericht")}
                                className="input-box"
                                style={{height: "97px", textAlign: "left", paddingTop: "10px", resize: "none"}}
                            />
                            <br/>
                            <br/>
                            <div className="text-center">
                                <CustomButton className={!_.isEmpty(contact_us) ? "common-button" : "common-button-disable"}
                                              onClick={() => handleSubmit()}>{isFetching ? t("Sending...") : t("Verzenden")}</CustomButton>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="form-box">
                <h4>{t('Wat gebeurt er daarna?')}</h4>
                <div className="d-flex mb-2">
                    <div className="num">1</div>
                    <p>
                    {t('Een vertegenwoordiger van MosqApp neem zo spoedig mogelijk contact met u op.')}
                    </p>
                </div>
                {/*<div className="d-flex mb-2">*/}
                {/*    <div className="num">2</div>*/}
                {/*    <p>*/}
                {/*        MosqueApp representative will follow up with a personalized response to address your inquiry.*/}
                {/*    </p>*/}
                {/*</div>*/}
                {/*<div className="d-flex mb-2">*/}
                {/*    <div className="num">3</div>*/}
                {/*    <p>*/}
                {/*        MosqueApp representative will follow up with a personalized response to address your inquiry.*/}
                {/*    </p>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default ContactForm;

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../locales/en.json';
import nl from '../locales/nl.json';
import gr from '../locales/gr.json';
import fr from '../locales/fr.json';

console.log(en);

export const languageResources = {
  en: { translation: en },
  nl: { translation: nl },
  gr: { translation: gr },
  fr: { translation: fr },
};

i18next.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  lng: 'nl',
  debug:false,
  fallbackLng: 'en',
  resources: languageResources,
  backend: {
    loadPath: '../locales' // Update this path
  }
});

i18next.on('languageChanged', (lng) => {
  console.log('Language changed to', lng);
});

i18next.on('missingKey', (lng, ns, key) => {
  console.log(`Missing key ${key} in ${lng}/${ns}`);
});

export default i18next;

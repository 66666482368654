import React, { useState } from "react";
import Box from "../../../../../components/Box";
import Icon from "../../../../../components/Icon";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import DeleteComponent from "../../../components/DeleteComponent";
import Dropdown from "../../../../../components/Dropdown";
import { deletePlan, updatePlan, fetchPlanList } from "../../../mosqueAction";
import { truncate } from "lodash";
import { Input, Select } from "antd";
import {
  getPaymentRecurringOption,
  showNotification,
} from "../../../../../util/Utility";
import NotSavedPage from "./NotSavedPage";
import Label from "../../../../../components/Label";
import useActiveData from "../../../../../customHooks/useActiveData";
import { useTranslation } from "react-i18next";

const PlanCards = styled.div`
  position: relative;
  padding: 30px 30px 10px 30px;
  background: #ffffff;
  border: 0.804144px solid #eff0f7;
  box-shadow: 0px 2.37681px 16.6376px rgba(98, 106, 128, 0.16);
  border-radius: 12px;
  margin-bottom: 20px;
  min-height: 375px;
  .planNo {
    font-weight: 700;
    font-size: 20px;
    color: #1a1a1a;
    text-decoration-line: underline;
  }

  .planNo span {
    font-weight: 700;
    font-size: 15px;
    color: #80cbc4;
    text-transform: uppercase;
    text-decoration-line: underline;
  }

  .Dashborder {
    border-top: 1px dashed #a0aec0;
    margin: 10px 0;
  }
  .DashborderVertical {
    border-right: 1px dashed rgb(160, 174, 192);
    height: 41px;
    width: 1px;
  }

  .price {
    font-weight: 700;
    font-size: 38px;
    color: #1a1a1a;
  }

  .price del {
    font-weight: 600;
    font-size: 18px;
    color: #a0aec0;
    margin-left: 10px;
  }

  .planBtn {
    background: #a0aec0;
    border-radius: 200px;
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
    height: 44px;
    width: 100%;
    border: none;
    margin: 10px 0;
  }
  .deleteBtn {
    font-weight: 400;
    font-size: 18px;
    color: #fe1717;
    border: none;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    span {
      margin-left: 10px;
    }
  }
  .editBtn {
    font-weight: 700;
    font-size: 18px;
    color: #d0004b;
    border: none;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    span {
      margin-left: 10px;
    }
  }

  .planDetail {
    font-weight: 600;
    font-size: 18px;
    color: #1a1a1a;
    margin: 15px 0;
  }

  ul {
    margin-bottom: 0px;
    height: 100px;
  }

  li {
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: rgba(26, 26, 26, 0.67);
  }
`;

const EditBoxContainer = styled(Box)`
  display: flex;
  border-radius: 4px;
  border: 1px dashed var(--inactive, #a0aec0);
  background: var(--stroke, #eff0f6);
  width: 100%;
  padding: 2px 8px;
  margin-bottom: 10px;

  input {
    width: 100%;
    border: unset;
    background: #eff0f6;
    font-weight: 700;
  }
`;

const EditSelectContainer = styled(Box)`
  word-wrap: unset;
  .ant-select-selector {
    background: var(--stroke, #eff0f6);
  }
`;

const recurringOptions = getPaymentRecurringOption();

const ExistPlan = ({ handleEdit }) => {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const websiteData = useActiveData();

  const [show, setShow] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [errors, setErrors] = useState({});
  const [details, updateDetails] = useState(null);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [id, setId] = useState("");

  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const plans = useSelector(
    (state) => state.mosqueReducer.memberPlans?.planList
  );

  const renderRecurringType = (type) => {
    if (type === "quarterly") {
      return "quarter";
    }
    if (type === "monthly") {
      return "maand";
    }
    return "January";
  };

  const handleDelete = (flag, id) => {
    setShow(true);
    setId(id);
  };

  const optionPaymentDate = () => {
    let option = [];
    for (let i = 1; i <= 20; i++) {
      const data = {};
      data.label = i.toString()
      data.value = i.toString();
      option.push(data);
    }
    return option;
  };

  const handleChangeUser = (keyName, keyValue) => {
    const data = { ...details };
    data[keyName] = keyValue;
    updateDetails(data);
  };

  const handleValidation = () => {
    let isValid = true;
    let error = {};
    if (!details.plan_name.trim()) {
      isValid = false;
      showNotification("warning", t("Please enter plan name"));
    }
    if (!details.amount.trim()) {
      isValid = false;
      showNotification("warning", t("Please enter amount"));
    }
    if (!details.recurring_type) {
      isValid = false;
      showNotification("warning", t("Please select recurring type"));
    }
    if (!details.payment_day) {
      isValid = false;
      showNotification("warning", t("Please select payment day"));
    }
    return {
      isValid,
      error,
    };
  };

  const handleUpdatePlan = () => {
    setErrors({});
    let validate = handleValidation();
    if (validate.isValid) {
      const trimmedDetails = {};
      // Trim each attribute and assign it to trimmedDetails object
      for (const key in details) {
        if (typeof details[key] === "string") {
          trimmedDetails[key] = details[key].trim();
        } else {
          trimmedDetails[key] = details[key];
        }
      }

      let updatedValue = {...details};
      updatedValue.payment_day = parseInt(updatedValue?.payment_day);

      dispatch(updatePlan(updatedValue?._id, updatedValue));
    } else {
      setErrors(validate.error);
    }
  };

  const handleCancel = () => {
    setIsCancel(false);
    updateDetails(null);
    dispatch(fetchPlanList(activeEntity?._id));
  };

  const handleEditCancel = () => {
    setIsConfirm(false);
    handleCancel();
  }

  const handleEditClick = (item) => {
    updateDetails(item);
    setIsConfirm(true);
  }

  const handleEditable = () => {
    setIsEditable(true);
    setIsConfirm(false);
    handleChangeUser("is_confirm", true);
  }
 console.log(plans);
  return (
    <div >
      <Label className="p-3">{t('To Edit or Delete the existing plan, plz contact support@inhousen.com')}</Label>
      <div className="row p-3">
        {plans?.length === 0 ?
            <div className={"col-md-12"}>
              <br/><br/><br/>
              <div className="text-center">
                {t(websiteData?.PLAN_LIST_PAGE_BLANK_TEXT)}
              </div>
            </div>
          :
            null
        }
        {plans &&
          plans.map((item, index) => (
            <div className="col-md-4" key={index}>
              <PlanCards style={{display:'flex', flexDirection:'column', justifyContent:'space-evenly'}}>
                <div className="planNo" title={item?.plan_name}>
                  {!isEditable || details?._id !== item?._id ? (
                    <Box disply="flex">
                      <span>{truncate(item?.plan_name, { length: 22 })}</span>
                    </Box>
                  ) : (
                    <EditBoxContainer>
                      {/*<Box width="30%">Plan -</Box>*/}
                      <Box>
                        <input
                          type="text"
                          value={details?.plan_name}
                          onChange={(e) =>
                            handleChangeUser("plan_name", e.target.value)
                          }
                        />
                      </Box>
                    </EditBoxContainer>
                  )}
                </div>
                <div className="Dashborder"></div>
                <div
                  className="d-flex align-items-center price"
                  title={item?.amount}
                >
                  {!isEditable || details?._id !== item?._id ? (
                    <Box>€ {truncate(item?.amount, { length: 8 })}</Box>
                  ) : (
                    <EditBoxContainer>
                      <input
                        type="text"
                        value={details?.amount}
                        onChange={(e) =>
                          handleChangeUser("amount", e.target.value)
                        }
                      />
                    </EditBoxContainer>
                  )}
                </div>
                <div className="planDetail">{t('Lidmaatschap gegevens')}</div>
                {/* <ul>
                  {!isEditable || details?._id !== item?._id ? (
                    <Box>
                      <li>{t('Factureringsfrequentie')} - {item?.recurring_type === "monthly" ? "Maandelijks": null}</li>
                    </Box>
                  ) : (
                    <EditBoxContainer width="100%" ml="-15px">
                      <Box>
                        <Box display="flex" width="100%" alignItems="center">
                          <Box width="130px" fontSize="14px">
                          {t('Factureringsfrequentie')} -
                          </Box>
                          <Box>
                            <Dropdown
                              border="none"
                              boxShadow="none"
                              variant="green"
                              defaultText={t("Select type")}
                              color="#1a1a1aad"
                              fontWeight="500"
                              icon={false}
                              minWidth="90px"
                              width="120px"
                              styles={{
                                rightIcon: { right: "30%" }
                              }}
                              selectedValue={details?.recurring_type}
                              onOptionClick={(item) => handleChangeUser("recurring_type", item?.value)}
                              options={recurringOptions}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </EditBoxContainer>
                  )}
                  {!isEditable || details?._id !== item?._id ? (
                    <Box>
                      <li>
                      {t('Betalingsdatum')} - 1e van de{" "}
                        {item?.recurring_type === "quarterly" &&
                          "first month of"}{" "}
                        {renderRecurringType(item?.recurring_type)}
                      </li>
                    </Box>
                  ) : (
                    <EditBoxContainer width="100%" ml="-15px">
                      <Box>
                        <Box display="flex" width="100%" alignItems="center">
                          <Box width="130px" fontSize="14px">
                          {t('Betalingsdatum')} -
                          </Box>
                          <EditSelectContainer isActive>
                            <Dropdown
                              variant="coloured"
                              defaultText={t("Select type")}
                              border="none"
                              boxShadow="none"
                              color="#1a1a1aad"
                              fontWeight="500"
                              Icon="none"
                              width="40px"
                              styles={{
                                rightIcon: { right: "70%" },
                                scroll: { maxHeight: "100px !important" }
                              }}
                              selectedValue={details?.payment_day}
                              onOptionClick={(item) => handleChangeUser("payment_day", item?.value)}
                              options={optionPaymentDate()}
                            />
                          </EditSelectContainer>
                        </Box>
                      </Box>
                    </EditBoxContainer>
                  )}
                </ul> */}

                {/* <MarginBox></MarginBox> */}
                <div className="Dashborder"></div>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {details?._id === item?._id ? (
                    <button
                      className="deleteBtn"
                      onClick={() => setIsCancel(true)}
                    >
                      <Icon iconName="close" color="red" iconSize="2xs" />
                      <span>{t('Annuleren')}</span>
                    </button>
                  ) : (
                    <button
                      className="deleteBtn"
                      onClick={() => handleDelete(true, item?._id)}
                      disabled={!item.isEditable}
                      style={{color:!item.isEditable?'#CCCCCC':''}}
                    >
                      <Icon iconName="delete" color={item?.isEditable?"red":'#ccc'} />
                      <span>{t('Verwijderen')}</span>
                    </button>
                  )}

                  <div className="DashborderVertical"></div>

                  {details?._id === item?._id ? (
                    <button
                      className="editBtn"
                      onClick={() => handleUpdatePlan()}
                    >
                      <Icon iconName="save" />
                      <span>{t('opslaan')}</span>
                    </button>
                  ) : (
                    <button
                      className="editBtn"
                      onClick={() => handleEditClick(item)}
                      disabled={!item.isEditable}
                      style={{color:!item.isEditable?'#CCCCCC':''}}
                    >
                      <Icon stroke={(item?.isEditable)?"":'#ccc'} color={(item?.isEditable)?"":'#ccc'} iconName="AdminEdit" />
                      <span>{t('Bewerken')}</span>
                    </button>
                  )}
                </Box>
              </PlanCards>
            </div>
          ))}
      </div>

      <DefaultModal
        show={isConfirm}
        onHide={() => handleEditCancel()}
        className="congrats-modal"
        style={{ paddingRight: "0px" }}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="d-flex justify-content-between">
          <div></div>
          <div onClick={() => handleEditCancel()}>
            <Icon iconName="blackCross" />
          </div>
        </div>
        <div className="congrats-screen">
          <Box display="flex" justifyContent="center">
            <Icon iconName="notSaved" />
          </Box>
          <Label justifyContent="center" mb="10px" fontSize="18px" fontWeight={700}>{t('Weet u zeker dat u dit lidmaatschap wilt bewerken?')}</Label>
          <Label justifyContent="center" fontSize="16px" color="#7E7E7E">
          {t('Alle gekoppelde gebruikers krijgen deze nieuwe waarde toegewezen. Weet u het zeker?')}
          </Label>

          <div className="d-flex justify-content-around mt-3">
            <button className="prev-bt" onClick={() => handleEditCancel()}>
            {t('Annuleren')}
            </button>
            <button
                className="fill-bt"
                onClick={() => handleEditable()}>
              {t('Lidmaatschap bewerken')}
            </button>
          </div>
        </div>
      </DefaultModal>

      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="congrats-modal"
        style={{ paddingRight: "0px" }}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <DeleteComponent
          text={
            t("U verliest permanent de details en betalingsinformatie van dit lidmaatschap.")
          }
          handleDelete={() => dispatch(deletePlan(id))}
          show={show}
          setshow={setShow}
        />
      </DefaultModal>
      <DefaultModal
        show={isCancel}
        onHide={() => setIsCancel(false)}
        className="congrats-modal"
        style={{ paddingRight: "0px" }}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <NotSavedPage
          handleEdit={() => handleUpdatePlan()}
          setShow={() => handleCancel()}
        />
      </DefaultModal>
    </div>
  );
};

export default ExistPlan;
